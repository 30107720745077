import axios from "axios";
import { store } from "../store/store";
import { logoutUser } from "../store/actions/userActions";
// export const SERVER_URL = "http://raven-env.eba-qxhmf2dk.eu-north-1.elasticbeanstalk.com/api/v1";
// export const SERVER_URL = "http://localhost:8000/api/v1/";
// export const SERVER_URL = "https://api.ravenandmacaw.com/api/v1/";
// export const SERVER_URL = "https://api.ravenmacaw.com/api/v1/";
export const SERVER_URL = "https://api.snioptom.com/chaddertonV2/public/api/v1/";
// export const SERVER_URL = "https://api.snioptom.com/sloaneapis/public/api/v1/";
// export const SERVER_URL = "https://api.effingo.ng/public/api/v1/";
// export const SERVER_URL = "https://api.racheleye.org/api/v1/";

export const apiInstance = axios.create();
// Add your interceptors here
apiInstance.defaults.baseURL = SERVER_URL;
apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      let currentPath = window.location.pathname;
      localStorage.setItem("currentPath", currentPath);
      localStorage.setItem(
        "sessionMessage",
        "Raven session expired please login again"
      );
      localStorage.removeItem("FBIdToken"); // <-- add your var
      store.dispatch(logoutUser());
      window.location = "/login"; // <-- add your path
    } else {
      return Promise.reject(error);
    }
  }
);
// apiInstance.interceptors.request.use((config) => {});
// apiInstance.interceptors.response.use((response) => {});

// export const setAuthorizationHeader = (token) => {
//   const FBIdToken = `Bearer ${token}`;
//   localStorage.setItem("FBIdToken", FBIdToken);
//   apiInstance.defaults.baseURL = SERVER_URL;
//   apiInstance.defaults.headers.common["Authorization"] = FBIdToken;
//   // Add a response interceptor
// };
